<template>
 <div>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="36"
    >
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-title
          space="0"
          title="Berlangganan Newsletter Kami"
          class="body-1 text-capitalize"
        />

        <v-responsive
          class="px-4 mx-6"
          max-width="600"
          width="100%"
        >
          <base-text-field
            hide-details
            label="Input alamat email Anda disini"
            v-model="email"
            id="email"
          >
          <!-- @keyup.enter="Confirm" -->
            <template v-slot:append-outer>
              <v-btn
                class="ml-n1"
                height="40"
                outlined
                style="margin-top: -7px;"
                :disabled="textemail === 'DAFTAR'?false:true"
              >
              <!-- @click="Confirm" -->
                {{textemail}}
              </v-btn>
            </template>
          </base-text-field>
        </v-responsive>
      </v-row>
    </base-section>
  </v-theme-provider>
   <v-snackbar v-model="snackbar.dialog" :color="snackbar.color">
      {{snackbar.text}}
    </v-snackbar>
    </div>
</template>

<script>
  export default {
    name: 'SectionNewsletterAlt',
    data: () => ({
      email: '',
      validate: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      textemail: 'DAFTAR',
      snackbar: {
        dialog: false,
        color: 'green',
        text: ''
      }
    }),
    methods: {
      Confirm() {
        let getemail   = this.email
        this.textemail = 'Loading...'
        if (this.validate.test(getemail)) {
          let formdata = {
                url_type: 'subscribe',
                web_member_email: getemail
          }
          let param      = this.$functions.ParamPOST(formdata)
          this.$axios.post(this.$functions.UrlPOST('apiWebSubscriber'),param,{
          headers: {
              'Content-Type': 'text/plain; charset=ISO-8859-1'
          }
          })
          .then(response => {
            let feedback    = response.data
            if (feedback.length > 0) {
              if (feedback[0].feedback === 'Y') {
                this.snackbar.dialog = true
                this.snackbar.color  = 'green'
                this.snackbar.text   = 'Terimakasih email anda sudah terdaftar'
              } else {
                this.snackbar.dialog = true
                this.snackbar.color  = 'red'
                if (feedback[0].feedback.includes('Registered')) {
                  this.snackbar.text   = 'Mohon maaf Email anda sudah terdaftar sebelumnya'
                } else {
                  this.snackbar.text   = feedback[0].feedback
                }
              }
              this.textemail       = 'DAFTAR'
            }
          })
          .catch(e => {
              this.snackbar.dialog = true
              this.snackbar.color  = 'red'
              this.snackbar.text   = e
              this.textemail       = 'DAFTAR'
          })
        } else {
          this.snackbar.dialog = true
          this.snackbar.color  = 'red'
          this.snackbar.text   = 'Mohon maaf email yang anda masukkan invalid'
           window.setTimeout(function () { 
              document.getElementById('email').focus(); 
          }, 3000)
          this.textemail       = 'DAFTAR'
        }
      }
    }
  }
</script>
